<template>
  <div>
    <hero-page
      illustration="/img/contact-page-illustration.svg"
    >
      <template #title>
        <h1 class="font-weight-bold text-uppercase text-h4 white--text">
          Offering First in Class <br v-if="$vuetify.breakpoint.mdAndUp" /> Products
          and Services
        </h1>
      </template>
      <template #under-title>
        <div ref="contactUsButton">
          <v-btn
            rounded
            color="teal accent-4"
            :x-large="$vuetify.breakpoint.mdAndUp"
            class="my-md-16 mt-3 py-md-5 py-2 px-md-10 font-weight-bold"
            @click="$router.push({name: 'base-contact'})"
          >
            CONTACT US
          </v-btn>
        </div>
      </template>
    </hero-page>
    <div class="px-5" v-if="$vuetify.breakpoint.xs">
      <div class="mb-1 font-weight-medium text-h5">Learn More</div>
      <div class="d-flex align-center">
        <div class="font-weight-bold text-subtitle">{{ services[currentItem].title }}</div>
        <v-spacer></v-spacer>
        <v-menu bottom min-width="50vw" content-class="rounded-lg mt-10 mr-3">
          <template #activator="{ on }">
            <div v-on="on">
              <div class="d-flex flex-column align-center justify-center selectButton" >
                <small class="black--text">Select</small>
                <v-icon color="black">mdi-chevron-down</v-icon>
              </div>
            </div>
          </template>
          <v-list class="grey darken-3 font-weight-medium text-body-2" dark dense>
            <v-list-item v-for="(item, idx) in services" :key="item.id" @click="currentItem = idx">
              <v-list-item-content>
                {{ item.title }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <v-container fluid class="pa-0">
      <div class="d-flex px-10 mb-5 align-center" v-if="$vuetify.breakpoint.smAndUp">
        <div class="text-h5 font-weight-medium ml-9">Learn More</div>
        <v-spacer></v-spacer>
        <div class="text-h3 font-weight-bold tab-header">{{ services[currentItem].title }}</div>
      </div>
      <div class="d-flex">
          <div class="p-0 divider d-flex" v-if="$vuetify.breakpoint.smAndUp">
            <v-tabs v-model="currentItem" vertical class="products__tabs">
                <v-tab class="responsive-tab" v-for="item in services" :key="item.id + '-title'">
                  {{ item.title }}
                </v-tab>
            </v-tabs>
          </div>
          <div class="flex-grow-1 products__description">
            <v-tabs-items v-model="currentItem" class="flex-grow-1">
              <v-tab-item v-for="item in services" :key="item.id + '-description'" transition="expand-transition">
                <vue-markdown 
                class="$vuetify.breakpoint.smAndUp ? 'px-10 mx-10 content' : 'content'"
                >
                  {{ item.description }}
                </vue-markdown>
              </v-tab-item>
            </v-tabs-items>
          </div>
      </div>
    </v-container>
    <div class="text-center py-10">
      <v-btn
        rounded
        color="black"
        large
        class="px-10 py-5"
        dark
        @click="$router.push({name: 'base-contact'})"
      >
        Contact Us
      </v-btn>
    </div>
  </div>
</template>

<script>
import HeroPage from "@/components/ui/HeroPage.vue";
import { mapActions } from 'vuex'
import VueMarkdown from "vue-markdown";

export default {
  name: "ProductsPage",
  components: { HeroPage, VueMarkdown },
  data: () => ({
    services: [],
    currentItem: 0
  }),
  methods: {
    ...mapActions("coreServicesStore", ["getCoreServices"]),
  },
  async mounted() {
    this.services = await this.getCoreServices()
    if (this.$route.params.selected) {
      const service = this.services.findIndex(el => el.id == this.$route.params.selected)
      this.currentItem = service
    }
  }
}
</script>

<style lang="scss" scoped>
.products__description {
  letter-spacing: normal;

  @include screen('onlyMd') {
    font-size: .9rem;
    padding: auto;
  }
  @include screen('onlySm') {
    padding-right: 2em;
    padding-left: 2em;
  }
}
.divider {
  border-right: 2px solid #d1d3da;
}
.products__tabs {
    padding: 0;
    .v-tab {
        justify-content: flex-start;
        text-transform: capitalize;
        font-size: 1.2rem;
        letter-spacing: normal;
        font-weight: 500;
        color: black;
        margin-left: 60px;
        max-width: unset;
        @media (max-width: 620px) {
            padding: 1.3rem;
            font-size: 1rem;
        }
        &--active {
            font-weight: 600 !important;
            color: black;
        }
        &:last-child {
          margin-bottom: 3em;
        }
    }
    :deep(.v-tabs-slider-wrapper) {
        left: -45px !important;
        .v-tabs-slider {
          width: 100px;
          height: 68px;
          background-size: 100%;
          background-position: center center;
          background-image: url(/img/indicator.8dbbe6dc.svg);
          background-color: transparent;
        }
    }
}
.responsive-tab {
  font-size: 1rem !important;
  @media only screen and (min-width: 2000px) {
    margin-right: 12vw;
  }
}
.tab-header {
  margin-right: auto;
  margin-left: auto;
}

.selectButton {
  background-color: #ddd;
  padding: 4px;
  border-radius: 10px;
  margin-bottom: 5px;
}
</style>